.doneModalBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;


	.doneModalText {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 30px;
		text-align: center;
	}

	.reviewRate {
		font-size: 40px;
	}

	textarea {
		width: 80%;
		height: 5rem;
		border-radius: 12px;
		padding: 5px;
		border: none;
		outline: none;
		resize: none;
	}
}