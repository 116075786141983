.servicesMain {

	.serviceBody1 {
		display: flex;
		flex-direction: column;
		margin: auto 16px;



		.serviceBody {
			display: flex;
			flex-direction: column;
			border-bottom: 2px solid;
			border-bottom-color: #ebebde;
			height: 100px;
			justify-content: space-between;
			align-items: center;

			.serviceFee {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 10px;

				.icon {

					color: #DEDED5;
				}

				.serviceFeeText {
					margin-left: 5px;
					color: #B0B0A2;
				}

				.serviceFeeNumber {
					margin-left: 5px;
					color: #515142;
				}
			}

			.serviceBody2 {
				margin-top: 10px;
				width: 100%;
				display: flex;
				justify-content: space-between;
			}

			.serviceCards {
				display: flex;
				align-items: center;

				.icon {
					color: #DEDED5;
				}

				.names {
					margin-left: 10px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.serviceName {
						font-size: small;
						color: #B0B0A2;
					}

					.serviceProviderName {
						color: #282826;
					}
				}
			}

			.serviceDuration {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-right: 20px;

				.serviceDurationText {
					font-size: small;
					color: #B0B0A2;
				}

				.serviceDurationNumber {
					color: #515142;
				}
			}

		}
	}
}