main.queueOne {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	height: calc(var(--page-height) - 67px);
	overflow-y: scroll;
}

.font-size-5 {
	font-size: 2rem;
}
.font-size-3 {
	font-size: 1.5rem;
}

.QONextBtn {
	background-color: transparent;
	color: #000;
	border: 1px solid#B5B5AD;
	margin: 20px 0;
}

.updateBtn {
	position: absolute;
	right: 10px;
	top: 20px;
}

.clientsBtn {
	position: absolute;
	left: 11px;
	top: 15px;
	border: 0;
	background-color: transparent;
}

.clientsBtn img {
	width: 33px;
	height: 33px;
	filter: grayscale(1);
}

.w-45 > * {
	width: 45% !important;
}

.shareQO {
	padding: 17px 17px 0 0;
}
