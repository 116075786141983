.error-message {
	color: red;
}

.success-message {
	color: green;
}

.welcomeUsername {
	display: flex;
	align-items: center;
	position: relative;

	.welcomeSpinner {
		position: absolute;
		right: 10px;
	}
}