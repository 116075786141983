.hisHeadChoise {
	width: 100%;
	display: flex;
	justify-content: space-around;
	margin-bottom: 7px;
	text-align: center;
}

.hisHeadChoise h4 {
	flex: 1;
	transition: 0.5s;
	color: #c1c1c1;
}

.his {
	height: calc(var(--page-height) - 103px);
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.hisGot {
	padding: 0.5rem 1rem;
	display: flex;
	flex-direction: column;
}

.hisGot:not(:first-child) {
	border-top: 1px solid #ebebde;
}

.queueNameRating {
	display: flex;
	gap: 10px;
	align-items: center;
	color: #c1c1c1;
	font-size: small;
}

.queueRating {
}

.queueIconRating {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.hisQueue {
	display: flex;
	align-items: center;
	padding: 0.3rem 0;
}

.iconGreen {
	color: #71b725;
}

.iconYellow {
	color: #e4ae23;
}

.iconGray {
	color: #b0b0a2;
}

.iconOrange {
	color: #f97125;
}

.iconRed {
	color: #f03e26;
}

.hisHeadChoise h4.h4a {
	color: #282828;
}
