header.QCHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
}

main.QCMain {
	display: flex;
	flex-direction: column;
	padding: 16px;
	height: calc(var(--page-height) - 67px);
	overflow-y: auto;
}

.QCMain label {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.QCMain span {
	font-size: 17px;
	line-height: 20px;
	color: #515142;
}

.QCMain .dropdown {
	border: none;
}

.QCMain .dropdown img {
	border-radius: 50%;
	height: 40px;
}

.QCMain .dropdown .dropdownitem {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.QCMain .inputDiv {
	display: flex;
	gap: 20px;

	button {
		border: 0;
		outline: 0;
		padding: 0;
		background-color: transparent;
	}
}

.QCMain input {
	border: 0;
	outline: 0;
	background-color: transparent;
	height: 40px;
	padding-left: 1rem;
	border-bottom: 1px solid #ebebde;

	&:focus-visible {
		outline: 0;
		border-bottom: 1px solid #7cd02b;
	}
}

.QCMain input:not(.QCTime):not(#checkb0x) {
	flex-grow: 1;
}

.QCTimeLab {
	display: flex;
	width: 50%;
}

.QCTime {
	width: 100%;
}

#checkb0x {
	width: 62px;
	height: 32px;
	border-radius: 25px;
	position: relative;
	background: #adada5;
	transition: all 0.2s;
	appearance: none;
	margin: 14px 0;
}

#checkb0x::after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background: white;
	box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
	transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
}

#checkb0x:checked::after {
	transform: translatex(30px);
}

#checkb0x:checked {
	background: #8dd636;
}

.min-h-0 {
	min-height: 0 !important;
	max-height: 0 !important;
}

[drop] {
	overflow: hidden;
	transition: 0.5s;
	transition-timing-function: cubic-bezier(1, 0, 0, 1);
	min-height: 60px;
	max-height: 60px;
}