.imHereButtonDiv {
	width: 150px;
	height: 133px;

	.ImHereButton {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		cursor: pointer;
		border: none;
		font-size: 20px;
		overflow: hidden;

		.imHereBtText {
			display: inline-block;
			animation: pulse 1.5s infinite;
		}
	}
}

.appModalText {
	font-size: 20px;
	text-align: center;
}

.appModal {
	background: rgba(50, 50, 47, 0.70);
	backdrop-filter: blur(5px);
}

.appModal .modal-content {
	border-radius: 12px;
	padding: 20px;
	margin: 0 25px;
}


.appModal .modal-footer {
	border: none;
}

@keyframes pulse {

	0%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.3);
	}
}