.QAMain {
	overflow-y: auto;
	height: calc(var(--page-height) - 148px);
	display: flex;
	flex-direction: column;
}

.QA {
	display: block;
	padding: 0 1rem;
	border-bottom: 1px solid #ebebde;
	color: #282826;
}

.QA:hover {
	color: #282826;
}

.QAProp {
	display: flex;
	padding: 20px 0;
	color: #bdbdb2;
}

.QAProp > div {
	padding-left: 1rem;
}

.QADate {
	color: #515142;
}
.QANew {
	position: fixed;
	width: 100%;
	bottom: 80px;
	height: 72px;
}

.QANewBtn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.QANewBtn:hover {
	color: white;
}
