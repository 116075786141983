.headerFixed {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 1000;
}


.serviceOneContainer {
	display: flex;
	justify-content: center;
	flex-direction: column;
	overflow-y: auto;
	margin-top: 67px;
	padding: 1rem;


	.serviceOneCard {
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		.icon {
			color: #DEDED5;
		}

		.serviceOneIcon {
			margin-top: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.serviceOneTable {
			margin-top: 20px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 2px solid;
			border-bottom-color: #DEDED5;
			padding: 0 10px 10px 10px;

			.serviceOneTIcon {
				display: flex;
				align-items: center;

				.icon {
					padding-right: 10px;
				}

				.serviceOneTableText {
					font-size: large;
					color: #B0B0A2;
				}
			}

			.serviceOneTableDuration {
				font-size: large;
				margin-left: 10px;
				color: #515142;
			}
		}

	}
}


.serviceOneRating {
	padding-top: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.custom-modal.modal {
	background: rgba(50, 50, 47, 0.70);
	backdrop-filter: blur(5px);
}

.custom-modal .modal-content {
	margin: 0 25px;
	border-radius: 15px;
	min-height: 300px;
	background-color: #FEFEF4;
}

.custom-modal .modal-header {
	border-bottom: none;
	display: flex;
	justify-content: center;
	padding-top: 30px;
}

.serviceOneBT {
	display: flex;
	gap: 20px;
	padding-top: 20px;

	.seviceBT1 {
		background-color: transparent;
		color: #282826;
		border-color: #B5B5AD;
		border-radius: 12px;
		height: 50px;
		font-size: 16px;
		flex: 2;
	}

	.seviceBT2 {
		background-color: #8DD636;
		border: none;
		border-radius: 12px;
		height: 50px;
	}

	.serviceBT3 {
		padding: 0 !important;
	}
}

.custom-modal .modal-header .modal-title {

	font-size: 20px;
	font-weight: 600;
	text-align: center;
}

.custom-modal .modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 17px;
	color: #282826;
}

.custom-modal .modal-footer {
	border-top: none;
}

.serviceOneRatingHeader {
	width: 100%;
	display: flex;
	gap: 15px;
	justify-content: space-between;
	margin-top: 10px;
}

.serviceNotifyBT {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
}

.serviceOneRatingBody {
	border-top: 1px solid #ebebde;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 70%;
}

.serviceOneRatingStars {
	font-size: small;
}

.serviceOneRatingText {
	font-size: 15px;
	font-weight: 600;
}

.serviceOneRatingComment {
	font-size: 13px;
	font-weight: 400;
}

.serviceOneRatingDate {
	font-size: 13px;
	font-weight: 400;
	color: #515142;
}



@media (max-width: 600px) {
	.serviceOneContainer {
		overflow-y: auto;

		.serviceOneCard {
			margin-top: 0px;


			.serviceOneBT {
				display: flex;
				gap: 20px;
				padding-top: 20px;

				.seviceBT1 {
					width: 50%;
					background-color: transparent;
					color: #282826;
					border-color: #B5B5AD;
					border-radius: 12px;
					height: 50px;
				}

				.seviceBT2 {
					width: 50%;
					background-color: #8DD636;
					border: none;
					border-radius: 12px;
					height: 50px;
				}
			}
		}

		.serviceOneRating {
			margin-bottom: 85px;
		}
	}
}