@font-face {
	font-family: 'Geometria';
	src: url('./fonts/Geometria.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'Geometria';
	src: url('./fonts/Geometria-Bold.ttf') format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('./fonts/SF-Pro-Display-Regular.otf') format('opentype');
	font-weight: normal;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('./fonts/SF-Pro-Display-Medium.otf') format('opentype');
	font-weight: 500;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('./fonts/SF-Pro-Display-Bold.otf') format('opentype');
	font-weight: bold;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('./fonts/SF-Pro-Display-Semibold.otf') format('opentype');
	font-weight: 600;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background-color: var(--tg-theme-bg-color);
	color: var(--tg-theme-text-color);
	font-family: 'SFProDisplay';
}
a:-webkit-any-link,
a:hover {
	text-decoration: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
p,
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
}
ul,
ol {
	list-style: none;
}
.Toastify__toast {
	touch-action: none;
}
html {
	height: 100%;
}
body {
	display: flex;
	font-size: 1rem;
	line-height: 1.2;
	background-color: #fffff5;
	min-width: 100%;
	flex-direction: column;
	color: #000;
}
/* body::-webkit-scrollbar{width:0px;height:var(--page-height)} */
#navbat-root {
	display: flex;
	flex: 1;
	flex-direction: column;
}
.navFooter {
	background-color: #32322f;
	height: 80px;
	padding: 0;
	position: fixed;
	bottom: 0px;
	width: 100%;
	padding-bottom: 8px;
}
.btnFooterNav:focus,
.btnFooterNav:hover {
	color: #696964;
	outline: 0;
}
.btnFooterNav {
	display: inline-block;
	font-size: 13px;
	line-height: 1rem;
	text-align: center;
	color: #696964;
}
.btnFooterNav.active,
.btnFooterNav:focus.active,
.btnFooterNav:hover.active {
	color: #fff;
}
@media (max-width: 768px) {
	.btnFooterNav {
		padding: 0.5em 1em;
	}
}
.mainTopTitle {
	color: #32322f;
	padding: 17px;
	font-size: 17px;
	font-weight: 700;
	line-height: 33px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
}
.labelText {
	font-size: 15px;
	line-height: 18px;
	color: #bdbdb2;
}
.formInput {
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #deded5;
	padding: 5px 0 10px;
	color: #515142;
	font-size: 17px;
	line-height: 20px;
}
.formInput:focus-visible {
	outline: 0;
	border-bottom: 1px solid #7cd02b;
}
.iconForm {
	margin: 0 1rem 0 11px;
	color: #deded5;
}
.formGroup {
	margin-right: 15px;
}

.btnSubmit {
	width: 100%;
	padding-bottom: 0;
	margin: auto 0 0;
}
[submit] {
	background-color: #8dd636;
	font-size: 20px;
	font-weight: bold;
	border-radius: 12px;
	border: 0;
	width: 100%;
	height: 50px;
	color: #fff;
}
[submit]:active {
	opacity: 0.7;
}
[submit]:disabled {
	cursor: not-allowed;
	opacity: 0.8;
}
[submit].cGray {
	background-color: #b5b5ad;
	color: white;
}

[type='radio'] {
	display: none;
}
.cRed {
	background-color: #fd4b13;
}
.forgotPass {
	display: flex;
	justify-content: flex-end;
}
.forgotPass a {
	color: #9d9d8c;
}
.forgotPass a:hover {
	color: #9d9d8c;
	text-decoration: none;
}
.redirectUser {
	color: #515142;
	display: flex;
	justify-content: center;
	margin: 20px 0;
}
.cabinetRow {
	padding: 16px 0;
	border-bottom: 1px solid #ebebde;
	color: #b3b3a5;
	display: flex;
	align-items: center;
}

.cabinetModals {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.cabinetTextInput {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #ebebde;

		p {
			color: #b3b3a5;
			font-size: 15px;
			margin-bottom: 5px;
		}

		input {
			font-size: large;
		}
	}

	input {
		border: none;
		outline: none;
	}
}

.cabinetModal .ant-modal-content {
	border-radius: 12px;
	padding: 20px;
	margin: 0 25px;
}

.cabinetModalBt {
	display: flex;
	gap: 10px;
}

.cabinetNamesError {
	color: red;
}

.cabinetChangeBlocked {
	color: #deded5;
}

.cabinetNamesSuccess {
	color: green;
}

.cabinetImgContainer {
	position: relative;
	width: 70px;
	height: 70px;
	min-width: 70px;
	border-radius: 50%;
	overflow: hidden;

	.cabinetImg {
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 1;
	}

	.cabinetFile {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 25px;
		position: absolute;
		top: 70%;
		background-color: rgba(0, 0, 0, 0.5);
		transition: all 0.2s;
		transform: translateY(100%);

		* {
			color: #fff;
		}
	}

	&:hover {
		.cabinetFile {
			transform: translateY(0);
		}
	}
}

.queueOne .cabinetRow > div {
	min-width: 135px;
}
a.cabinetRow:hover {
	color: #b3b3a5;
}
.dark {
	color: #515142;
}
.cabinetUser {
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	padding-top: 10px;
	color: #515142;
}
.cGreen {
	padding-left: 0.5rem;
	color: #73cc2c;
}
.modalForm {
	display: flex;
	flex-direction: column;
	height: 100%;
}
form.modalForm :not(input) {
	border: 0;
}
.fscreen-dialog {
	margin: 0;
	height: 100%;
	max-width: 100%;
}
.fscreen-content {
	border: 0;
	border-radius: 0;
	height: 100%;
	background-color: #fffff5;
}
.linkAsBtn {
	-webkit-appearance: button;
	-moz-appearance: button;
	appearance: button;
	text-decoration: none;
}
.h-page {
	height: var(--page-height);
}
