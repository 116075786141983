.result {
	display: flex;
	flex-direction: column;
	margin: 0 1rem;
	height: calc(var(--page-height) - 67px);
	overflow-y: auto;
}

.result h2 {
	font-size: 19px;
	line-height: 25px;
}

.observeBody {
	display: flex;
	align-items: center;

	.icon {
		color: #deded5;
		padding-bottom: 16px;
	}
}

.observeTitle {
	color: #b2b2a5;
	font-size: 15px;
}

.result .observeLabel {
	margin-top: 10px;
	height: 160px;
	border-radius: 12px;
	background-color: #e3e3d4;
}

.cabinetRow:last-child figcaption {
	border: 1px solid;
	border-radius: 20px;
	padding: 20px;
	background-color: #f4f4e5;
}

.qrCodeImg {
	padding: 20px;
	border-bottom: 1px solid #e3e3d4;
}

.qrCode {
	width: 100%;
	height: 100%;
}

.weekdays {
	padding: 15px 0;
	width: 100%;
	gap: 6px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-left: 10px;
}

.observeQueueCount {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: self-start;
}

.observeImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 12px;
}
.workdays {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 7px;
	height: 32px;
	border-radius: 6px;
	width: 38px;
}

.workdaysNames {
	font-size: 14px;
}

.observeNumStatus {
	display: flex;
	justify-content: center;
	gap: 50px;
	margin-top: 20px;
}

.observeNoteWorkingHours {
	flex: 1;
	border-bottom: 1px solid #e3e3d4;
	padding: 8px 0;
	display: flex;
	justify-content: space-between;
	margin-left: 10px;
}

.observeWorkingHours {
	border-bottom: 1px solid #e3e3d4;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.ratingMainBody{
	margin-bottom: 20px;
}

.work {
	color: #515142;
	color: #ffff;
}

.red {
	background-color: #eeeee3;
	color: #515142;
}

.green {
	background-color: #79c21f;
	color: #ffff;
}

.today {
	background-color: blue;
	color: #ffff;
	border: 5px solid;
	border: 2px solid #fa0e0e;
	animation: brighten 2s infinite;
}
.notWork {
	width: 100%;
	display: flex;
	justify-content: center;
}

.notWorkText {
	margin-top: 50px;
	font-size: 30px;
	color: #e0503d;
}

.qrCodeDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 190px;
	background-color: #f4f4e5;
	border-radius: 12px;
	border: 1px solid #e3e3d4;
}

.qrCodeNumber {
	color: #282826;
	text-align: center;
	font-family: 'SF Pro Display';
	font-size: 33px;
	font-style: normal;
	font-weight: 700;
	line-height: 42px; /* 127.273% */
	padding-bottom: 10px;
}

.buttonModal {
	display: flex;
	justify-content: center;
}

@keyframes brighten {
	0% {
		border-color: #fa0e0e;
		box-shadow: 0 0 2px #fa0e0e;
	}
	50% {
		border-color: #ffffff;
		box-shadow: 0 0 15px #ffffff;
	}
	100% {
		border-color: #fa0e0e;
		box-shadow: 0 0 2px #fa0e0e;
	}
}

@media (max-width: 600px) {
	.result {
	}

	.result h2 {
		font-size: 16px;
		line-height: 22px;
	}

	.cabinetRow:last-child figcaption {
		padding: 15px;
	}
}
