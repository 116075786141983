header.home {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #fffff5;
	height: 165px;
}

.logoCont {
	margin: 0 auto;
	height: 5rem;
}

.logoCont img {
	margin: 20px 0;
}

.searchCont {
	color: #32322f;
	display: flex;
	margin: 0 10px;
	width: calc(100% - 20px);
	min-height: 44px;
	z-index: 6;
}

.searchCont.hint {
	background-color: #f4f4e5;
	padding: 10px;
	width: calc(100% - 20px);
	border-radius: 5px;
	position: fixed;
	top: 124px;
	box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
	border: 1px solid #deded5;
}

.searchCont.hint:active {
	filter: grayscale(0.8);
}

.searchCont input {
	border: 1px solid #deded5;
	border-right-width: 0;
	border-radius: 12px 0 0 12px;
	outline: 0;
	padding: 10px;
	background-color: #f4f4e5;
	flex-grow: 1;
}

.searchCont svg {
	padding: 10px;
	border: 1px solid #deded5;
	border-left-width: 0;
	border-radius: 0 12px 12px 0;
	background-color: #f4f4e5;
	width: 49px;
	height: 44px;
}

.searchCont i {
	margin: 6px;
	color: #979791;
	transition: 0.5s;
}

.textCont {
	color: #bdbdb2;
	font-size: 13px;
	line-height: 16px;
	padding: 20px 0 0 1rem;
	text-transform: uppercase;
	border-bottom: 1px solid #ebebeb;
}

.infoLogo {
	position: fixed;
	bottom: 100px;
	right: 20px;
	border-radius: 50%;
	padding: 15px 20px;
	background-color: #fac232;
	box-shadow: 0px 5px 17px #ffe49d;
}

main::-webkit-scrollbar {
	width: 0;
}

main.home {
	height: calc(var(--page-height) - 165px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.recordsIndex {
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-bottom: 1px solid #ebebde;
	cursor: pointer;
}

.recordsBorderGreen {
	border-left: 5px solid #79C21F;
}

.recordsIndex>div {
	padding-bottom: 20px;
}

.queueName {
	font-weight: 600;
	font-size: 19px;
	line-height: 25px;
	color: #282826;
}

.queueTime {
	font-size: 17px;
	line-height: 20px;
	color: #e48c24;
}

.custom-modal-home.modal {
	background: rgba(50, 50, 47, 0.70);
	backdrop-filter: blur(5px);
}

.custom-modal-home .modal-content {
	margin: 0 25px;
	border-radius: 15px;
	height: 200px;
	background-color: #FEFEF4;
}

.custom-modal-home .modal-header {
	border-bottom: none;
	display: flex;
	justify-content: center;
	padding-top: 30px;
}

.custom-modal-home .modal-header .modal-title {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
}

.custom-modal-home .modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 17px;
	color: #282826;
}

.custom-modal-home .modal-footer {
	border-top: none;
}

.queueCurrent {
	border: 1px solid #8ed727;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 77px;
	height: 50px;
	color: #515142;
}

.homeCardMain {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.homeCardQueueName {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	gap: 10px;
}

.queueNumCalling {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 34px;
	flex-shrink: 0;
	border-radius: 7px;
	border: 2px solid #8ED727;
	color: #282826;
	padding: 0 5px;
	background-color: #8ED727;
	color: #FFFFFF;
}

.queueNum {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 34px;
	flex-shrink: 0;
	border-radius: 7px;
	border: 2px solid #8ED727;
	color: #282826;
	padding: 0 5px;
}

.queueCrTimeText {
	font-size: 15px;
	line-height: 18px;
	color: #b3b3a5;
}

.queueCrTime {
	text-align: center;
	font-size: 17px;
	line-height: 20px;
	color: #515142;
}

.queueCurrentTime {
	display: flex;
	flex-flow: column;
	align-items: flex-end;
}

.queueBtn {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	font-size: 19px;
	line-height: 25px;
	padding: 8px 12px;
	border: 0;
}

.queueBtn.error {
	color: #fff;
	background-color: #e0503d;
}

.queueBtn.active {
	color: #fff;
	background-color: #71b725;
}

.queueBtn.wait {
	color: #fff;
	background-color: #f3ba26;
}

.queueBtn.bordered {
	background-color: transparent;
	border: 1px solid #b5b5ad;
	color: #000;
}

.queueBtn:hover {
	color: #fff;
}

.queueBtn.bordered:hover {
	color: #000;
}

.bgGray {
	background-color: #adada5;
	color: #fff;
}

.emptyHome {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	align-items: center;
	justify-content: center;
}

.emptyHomeHeader {
	font-size: 23px;
	line-height: 27px;
	text-align: center;
	padding: 15px;
	color: #282826;
}

.emptyHomeText {
	font-size: 19px;
	line-height: 25px;
	text-align: center;
	padding: 0 15px;
	color: #bdbdb2;
}

.cGray {
	color: #deded5;
}

.searchCont .focused {
	border-color: #7cd02b;
}

i.hideFocus {
	font-size: 0;
	margin: 0;
}

.cabinRow {
	display: flex;
	justify-content: center;
	border: 1px solid;
	border-radius: 20px;
	padding: 20px;
	background-color: #f4f4e5;
	margin: auto;
}

.buttonClose {
	display: flex;
	justify-content: center;
}

.tgButton {
	display: flex;

	input {

		border-radius: 5px 0 0 5px;
		// border-right-color: #fff ;
	}

	button {
		border: 1px solid #ced4da;
		border-radius: 0 5px 5px 0;
		// border-left-color: #fff ;
		padding: 5px;
	}
}

[tipforhome] {
	position: fixed;
	padding-top: 125px;
	width: -webkit-fill-available;
	height: calc(var(--page-height) + 80px);
	z-index: 5;
	opacity: 1;
	backdrop-filter: blur(5px);
	transition: all 0.5s linear;
}

@media screen and (max-width: 350px) {
	.queueBtn {
		font-size: 16px;
	}
}