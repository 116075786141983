.wishlistMain {
	display: flex;
	padding: 1rem 1rem;
	border-bottom: 1px solid #e0e0e0;
	align-items: center;
	justify-content: space-between;


	.wishlistBody {
		display: flex;



	}

	.wishlistName {
		color: #000;
	}

	.wishlistCode {
		color: #000;
	}

}


.WishlistMain {
	height: calc(var(--page-height) - 67px);

	.NoWishlistContainer {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px;



		.NoWishlist {
			text-align: center;
			color: #B2B2A5;
		}
	}
}